@import './assets/styles/theme/index.css';

@media screen and (max-width: 991px) {
    .MuiContainer-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.PhoneInputInput {
    font-size: 16px;
    color:#333;
    width: 100%;
    border: none;
    border-radius:6px;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 2px 5px 1px;
    outline: none;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    height: 48px;
    background: #eee;
    margin-right: 22px;
    -webkit-tap-highlight-color: transparent;
}

.MuiAlert-standardError{
    background: #f1003b !important;
    color: #fff !important;
}

.MuiAlert-icon {
    color:#fff !important;
}
