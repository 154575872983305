.bg-primary {
    background-color: var(--primary-color) !important;
}

.bg-secondary {
    background-color: var(--secondary-color) !important;
}

.bg-red {
    background-color: var(--red) !important;
}

.bg-green {
    background-color: var(--green) !important;
}

.bg-yellow {
    background-color: var(--yellow) !important;
}

.bg-orange {
    background-color: var(--orange) !important;
}

.bg-blue {
    background-color: var(--blue) !important;
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-gray {
    background-color: var(--gray) !important;
}

