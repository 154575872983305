:root {
    --background-page: #D9DCD6;
    --ihelp-primary: #FF7121;
    --primary: #6fa1e3;
    --theme-page: rgb(246, 243, 243);
    --blue: #01579b;
    --green: #1aa521;
    --yellow: #fdd835;
    --orange: #ef6c00;
    --red: #b71c1c;
    --dark: #3a3a3a;
    --dark2: #909090;
    --gray: rgba(206, 206, 206, 0.87);
    --black: #1a1a1a;
}
