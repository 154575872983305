* {
    box-sizing: border-box;
}

html, body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    background-color: #eee;
}

#root {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    max-width: 100%;
    overflow-x: hidden;
}



a,
a > button,
a > button > span {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.rccs,
.rccs__card {
    width: 100% !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-size: 16px !important;
}

.MuiFormLabel-root {
    font-size: 0.85rem !important;
}
