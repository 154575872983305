.text-primary {
    color: var(--primary-color) !important;
}

.text-secondary {
    color: var(--secondary-color) !important;
}

.text-red {
    color: var(--red) !important;
}

.text-green {
    color: var(--green) !important;
}

.text-yellow {
    color: var(--yellow) !important;
}

.text-orange {
    color: var(--orange) !important;
}

.text-blue {
    color: var(--blue) !important;
}

.text-dark {
    color: var(--dark) !important;
}

.text-gray {
    color: var(--gray) !important;
}

