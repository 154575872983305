.border-color-primary {
    border-color: var(--primary-color) !important;
}

.border-color-secondary {
    border-color: var(--secondary-color) !important;
}

.border-color-red {
    border-color: var(--red) !important;
}

.border-color-green {
    border-color: var(--green) !important;
}

.border-color-yellow {
    border-color: var(--yellow) !important;
}

.border-color-orange {
    border-color: var(--orange) !important;
}

.border-color-blue {
    border-color: var(--blue) !important;
}

.border-color-dark {
    border-color: var(--dark) !important;
}

.border-color-gray {
    border-color: var(--gray) !important;
}

