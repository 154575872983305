.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: white;
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 19px;
}

.MuiTableCell-sizeSmall {
    padding: 3px 10px !important;
}

.input-valid fieldset {
    box-shadow: 0 0 1px 1px #15c615;
    border-color: transparent !important;
}

.Mui-focused fieldset {
    transition: ease-in-out .1s;
    box-shadow: 0 0 3px 1px #326cc0;
    border-color: transparent !important;
}

